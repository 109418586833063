import React, { useEffect, useState } from "react";
import axios from "axios";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Series,
  CommonSeriesSettings,
  Legend,
  Export,
} from "devextreme-react/chart";
import PieChart from "devextreme-react/pie-chart";
import MapDumpsters from "../components/Map";

const baseURL = "https://eody.mainsys.eu/react-test.json";

export const Reports = () => {
  return (
    <div className="reports">
      <div className="title">
        <h1>Αναφορές</h1>
      </div>
    </div>
  );
};

export const ReportsOne = () => {
  return (
    <div className="reports">
      <div className="title">
        <h1>Χάρτης</h1>
      </div>
      <MapDumpsters />
    </div>
  );
};

export const ReportsTwo = () => {
  const [data, setData] = useState([]);
  const [gender1Count, setGender1Count] = useState(0);
  const [gender2Count, setGender2Count] = useState(0);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      const jsonData = response.data;
      setData(jsonData);
      filterDataByRange(jsonData, selectedRange);
    });
  }, [selectedRange]);

  const filterDataByRange = (jsonData, range) => {
    const { startDate, endDate } = range;
    const filteredData = jsonData.filter((item) => {
      const callDate = new Date(item._id.callDate.$date);
      return callDate >= startDate && callDate <= endDate;
    });

    let count1 = 0;
    let count2 = 0;
    filteredData.forEach((item) => {
      if (item._id.gender === 1) {
        count1++;
      } else if (item._id.gender === 0) {
        count2++;
      }
    });
    setGender1Count(count1);
    setGender2Count(count2);
  };

  const handleRangeChange = (ranges) => {
    setSelectedRange(ranges.selection);
  };

  const chartData = [
    { gender: "Ανδρας", count: gender1Count },
    { gender: "Γυναικα", count: gender2Count },
  ];
  return (
    <div className="reports">
      <div className="title">
        <h1>Διαγράμματα</h1>
      </div>
      <div className="datepicker">
        <DateRangePicker
          onChange={handleRangeChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          ranges={[selectedRange]}
        />
      </div>
      <div className="chart">
        <PieChart dataSource={chartData}>
          <CommonSeriesSettings argumentField="gender" />
          <Series valueField="count" argumentField="gender" type="pie" />
          <Legend
            horizontalAlignment="center"
            verticalAlignment="bottom"
            itemTextPosition="right"
          />
          <Export enabled={true} />
        </PieChart>
      </div>
      <div className="Gender"></div>
      <p>ΑΝΔΡΑΣ: {gender1Count}</p>
      <p>ΓΥΝΑΙΚΑ: {gender2Count}</p>
    </div>
  );
};

export const ReportsThree = () => {
  return (
    <div className="reports">
      <h1>Reports/reports3</h1>
    </div>
  );
};
