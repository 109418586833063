import React from "react";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Γενικά",
    path: "#",
    icon: <RiIcons.RiHome2Line />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Χρήστες",
        path: "/overview/users",
        icon: <RiIcons.RiUser3Line />,
      },
      {
        title: "Κάδοι",
        path: "/overview/bins",
        icon: <RiIcons.RiDeleteBin6Line />,
      },
      {
        title: "Ζυγίσεις",
        path: "/overview/weights",
        icon: <RiIcons.RiScales2Line />,
      },
    ],
  },
  {
    title: "Αναφορές",
    path: "#",
    icon: <RiIcons.RiFileList2Line />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Χάρτης",
        path: "/reports/map",
        icon: <RiIcons.RiMapPin5Line />,
        cName: "sub-nav",
      },
      // {
      //   title: "Διαγράμματα",
      //   path: "/reports/charts",
      //   icon: <RiIcons.RiBarChart2Line />,
      //   cName: "sub-nav",
      // },
      // {
      //   title: "Αναφορές 3",
      //   path: "/reports/reports3",
      //   icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
  {
    title: "Διαχείριση Κάδων",
    path: "/control",
    icon: <IoIcons.IoMdSettings />,
  },
  {
    title: "Υποστήριξη",
    path: "/support",
    icon: <IoIcons.IoMdHelpCircle />,
  },
];
