import React, { useState, useEffect } from "react";
import axios from "axios";
import Map from "devextreme-react/map";
import Button from "devextreme-react/button";
import CheckBox from "devextreme-react/check-box";
import myMarker from "../assets/img/map-marker.png";
import API_BASE_URL from "../config";

const markerUrl = myMarker;

const apiKey = {
  bing: "Au6TUYDtE3GdOlTTnrebFPKm_SD6amB7Hfe-ifLb5Aw2YqIeUuC5NfQl2d1b0LQO",
};

export default function MapDumpsters() {
  const [currentMarkersData, setCurrentMarkersData] = useState([]);
  const [currentMarkerUrl, setCurrentMarkerUrl] = useState(markerUrl);
  const [useCustomMarkers, setUseCustomMarkers] = useState(true);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/v1/bins/`)
      .then((response) => {
        const fetchedMarkersData = response.data.map((item) => ({
          location: [item.location[0].latitude, item.location[0].longitude],
          tooltip: {
            text: item.locationName,
          },
        }));
        setCurrentMarkersData(fetchedMarkersData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const onCustomMarkersChange = (value) => {
    setCurrentMarkerUrl(value ? currentMarkerUrl : null);
  };

  const showTooltips = () => {
    setCurrentMarkersData(
      currentMarkersData.map((item) => {
        const newItem = { ...item };
        newItem.tooltip.isShown = true;
        return newItem;
      })
    );
  };

  return (
    <React.Fragment>
      <Map
        defaultZoom={11}
        height={440}
        width="100%"
        controls={true}
        markerIconSrc={useCustomMarkers ? currentMarkerUrl : null}
        markers={currentMarkersData}
        provider="bing"
        apiKey={apiKey}
      />
      <div className="options">
        {/* <div className="caption">Options</div>
        <div className="option">
          <CheckBox
            defaultValue={true}
            text="Use custom marker icons"
            value={useCustomMarkers}
            onValueChanged={onCustomMarkersChange}
          />
        </div> */}
        {/* <div className="option">
          <Button text="Show all tooltips" onClick={showTooltips} />
        </div> */}
      </div>
    </React.Fragment>
  );
}
