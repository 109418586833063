import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TreeList,
  Scrolling,
  Paging,
  Pager,
  SearchPanel,
  Column,
  Sorting,
  Button,
} from "devextreme-react/tree-list";
import "devextreme-react/text-area";
import { Popup } from "devextreme-react/popup";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Form, Item } from "devextreme-react/form";
import API_BASE_URL from "../config";
import AddLocationComponent from "../components/AddLocation";

export const Control = () => {
  const [data, setData] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteAction, setDeleteAction] = useState(null);
  const [popupPosition, setPopupPosition] = useState({
    my: "center top",
    at: "center top",
  });

  const allowedPageSizes = [10, 20, 50];
  let itemId;
  let itemIdd;

  // GET
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/v1/bins/`)
      .then((response) => setData(response.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // DELETE
  const allowDeleting = (e) => {
    // Allow deleting all items except the one with ID 1
    return e.row.data._id !== 1;
  };

  const handleDelete = (e) => {
    itemId = e.row.data.locationName;
    itemIdd = e.row.data._id;
    setCurrentLocation({ locationName: itemId, _id: itemIdd }); // Set the location to be deleted
    setDeleteAction(() => confirmDelete); // Set the action to perform when confirming deletion
    setShowDeleteConfirmation(true);
    console.log("CurrentLocation: ", currentLocation);
  };

  const confirmDelete = () => {
    axios
      .delete(`${API_BASE_URL}/api/v1/bins/locations/${itemId}`, {
        data: {
          binId: itemIdd,
        },
      })
      .then((response) => {
        const updatedData = data.filter((item) => item._id !== itemId);
        setData(updatedData);
        window.location.reload(false);
      })
      .catch((error) => console.error("Error deleting data:", error));

    setShowDeleteConfirmation(false); // Hide the confirmation popup
  };
  console.log("data:", data);

  // EDIT POPUP

  const showPopup = (location) => {
    setCurrentLocation(location);
    setPopupVisible(true);
    // Adjust popup position based on the clicked element
    setPopupPosition({
      my: "center top",
      at: "center top",
      of: `#location${location._id}`,
    });
  };

  const hidePopup = () => {
    setPopupVisible(false);
    setCurrentLocation({});
  };

  //ADD NEW

  return (
    <div className="overview">
      <h1>Διαχείριση Κάδων</h1>
      <div className="add-location-container">
        <AddLocationComponent />
      </div>

      <TreeList
        dataSource={data}
        columnAutoWidth={true}
        wordWrapEnabled={true}
        showBorders={true}
        keyExpr="_id"
      >
        <Scrolling mode="standard" />
        <SearchPanel visible={true} />
        <Paging enabled={true} defaultPageSize={10} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
          showNavigationButtons={true}
        />
        <Sorting mode="multiple" />
        <Column dataField="_id" caption="Όνομα Κάδου" defaultSortOrder="asc" />
        <Column
          dataField="timestamp"
          caption="Τελευταία Ενημέρωση"
          dataType="date"
          defaultSortOrder="asc"
        />
        <Column
          dataField="locationName"
          caption="Περιοχή"
          defaultSortOrder="asc"
        />
        <Column
          dataField="battery"
          caption="Ποσοστό Μπαταρία (%)"
          dataType="number"
          defaultSortOrder="asc"
        />
        <Column
          dataField="fill"
          caption="Ποσοστό Πληρώτητας (%)"
          dataType="number"
          defaultSortOrder="asc"
        />
        <Column type="buttons">
          <Button
            name="delete"
            text="Διαγραφή"
            visible={true}
            onClick={handleDelete}
          />
          {/* <Button
            text="Edit"
            onClick={(e) => {
              console.log("Edit button clicked:", data);
              showPopup(data);
            }}
          /> */}
        </Column>
      </TreeList>
      <Popup
        visible={showDeleteConfirmation}
        title="Επιβεβαίωση Διαγραφής"
        width={400}
        height={150}
        onHiding={() => setShowDeleteConfirmation(false)}
        showCloseButton={true}
      >
        <Form colCount={2}>
          <p>Είστε σίγουροι ότι θέλετε να διαγράψετε την τοποθεσία;</p>
          <Item
            itemType="button"
            buttonOptions={{
              text: "Ναι",
              onClick: deleteAction,
              type: "danger",
            }}
          />
          <Item
            itemType="button"
            buttonOptions={{
              text: "Άκυρο",
              onClick: () => setShowDeleteConfirmation(false),
            }}
          />
        </Form>
      </Popup>

      <Popup
        visible={isPopupVisible}
        onHiding={hidePopup}
        title="Edit Location"
        width={400}
        position={popupPosition}
      >
        {/* Content of the popup */}
        <p>Editing Location: {currentLocation.locationName}</p>
        {/* ... Other input fields for editing ... */}
      </Popup>
    </div>
  );
};

export default Control;
