import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";
import Form, { Item, GroupItem, Label } from "devextreme-react/form";
import TextBox from "devextreme-react/text-box";
import { Autocomplete } from "devextreme-react/autocomplete";
import API_BASE_URL from "../config";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

const AddLocationComponent = () => {
  // State variables to manage the popup visibility and form inputs
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [newLocationId, setNewLocationId] = useState("");
  const [newLocationName, setNewLocationName] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [idOptions, setIdOptions] = useState([]);

  // Fetch location and ID options from the API
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/v1/locations`)
      .then((response) => {
        setLocationOptions(response.data.map((location) => location.name));
      })
      .catch((error) => {
        console.error("Error fetching location options:", error);
      });

    axios
      .get(`${API_BASE_URL}/api/v1/bins/free`)
      .then((response) => {
        setIdOptions(response.data); // Directly set idOptions to the response data
      })
      .catch((error) => {
        console.error("Error fetching ID options:", error);
      });
  }, []);
  console.log("idOptions: ", idOptions);
  // Function to handle form submission
  const handleFormSubmit = () => {
    const newLocationData = {
      locationName: newLocationName,
      binId: newLocationId,
    };
    const newbinId = {
      binId: newLocationData.binId,
    };
    axios
      .put(`${API_BASE_URL}/api/v1/bins/locations/${newLocationData.locationName}`, newbinId)
      .then((response) => {
        // Handle success (e.g., show a success message)
        console.log("Location added successfully:", response.data);
        setPopupVisible(false);
        window.location.reload(false);
        // You might want to refresh your data here
      })
      .catch((error) => {
        console.error("Error adding location:", error);
        console.log(newLocationData);
        // Handle error (e.g., show an error message)
      });
  };

  const buttonOptions = {
    text: "Προσθήκη",
    type: "success",
    onClick: function () {
      handleFormSubmit();
    },
  };

  return (
    <div className="add-location-container">
      {/* Button to trigger the popup */}
      <Button text="Προσθήκη κάδου" onClick={() => setPopupVisible(true)} />
      {/* Popup for adding new location */}
      <Popup
        visible={isPopupVisible}
        onHiding={() => {
          setPopupVisible(false);
          setNewLocationId("");
          setNewLocationName("");
        }}
        title="Προσθήκη κάδου"
        width={700}
        height={700}
      >
        <Form>
          <GroupItem colCount={2} caption="Λεπτομέριες Νέου Κάδου">
            {/* Input for ID */}
            <Item dataField="id">
              <Label text="ID Κάδου" />
              <Autocomplete
                dataSource={idOptions.map((item) => item._id.clientID)} // Extract clientID values
                value={newLocationId}
                onValueChanged={(e) => setNewLocationId(e.value)}
                placeholder="Arduino5"
              />
            </Item>
            {/* Input for Name */}
            <Item dataField="name">
              <Label text="Όνομα Περιοχής" />
              <Autocomplete dataSource={locationOptions} value={newLocationName} onValueChanged={(e) => setNewLocationName(e.value)} placeholder="Περιστέρι" />
            </Item>
          </GroupItem>
          {/* Button to submit the form */}
          <Item itemType="button" buttonOptions={buttonOptions} />
        </Form>
      </Popup>
    </div>
  );
};

export default AddLocationComponent;
