/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import { RiMenu2Fill, RiMailLine, RiNotificationLine } from "react-icons/ri";
import user from "../assets/img/user.png";
import { useLocation } from "react-router-dom";

const Nav = styled.div`
  background: #60c525;
  box-shadow: 0px 0px 2px black;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2.5rem 3rem;
`;

const Navigation = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    const sidebarNav = document.querySelector(".sidebar-nav");
    if (sidebarNav) {
      sidebarNav.classList.toggle("show");
    }
  };

  const location = useLocation();
  const getBreadcrumbs = (pathname) => {
    const breadcrumbs = [];

    if (pathname === "/overview") {
      breadcrumbs.push("Γενικά");
    } else if (pathname === "/overview/users") {
      breadcrumbs.push("Γενικά", "Χρήστες");
    } else if (pathname === "/overview/bins") {
      breadcrumbs.push("Γενικά", "Κάδοι");
    } else if (pathname === "/overview/weights") {
      breadcrumbs.push("Γενικά", "Ζυγίσεις");
    } else if (pathname === "/reports") {
      breadcrumbs.push("Αναφορές");
    } else if (pathname === "/reports/map") {
      breadcrumbs.push("Αναφορές", "Χάρτης");
    } else if (pathname === "/reports/charts") {
      breadcrumbs.push("Αναφορές", "Διαγράμματα");
    } else if (pathname === "/control") {
      breadcrumbs.push("Διαχείριση Κάδων");
    } // Add more cases for other routes

    return breadcrumbs;
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#60c525" }}>
        <Nav>
          <div className="header-container">
            <div className="header-left">
              <span
                className="header-left__mobile"
                onClick={handleToggleSidebar}
              >
                <RiMenu2Fill />
              </span>
              <div className="header-left__breadcrumbs">
                <ul className="breadcrumb">
                  {getBreadcrumbs(location.pathname).map(
                    (breadcrumb, index) => (
                      <li key={index}>
                        {index !== 0 && <span></span>}
                        <a href="#">{breadcrumb}</a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className="header-right">
              <div className="header-right__buttons">
                <span>
                  <RiMailLine />
                </span>
                <span>
                  <RiNotificationLine />
                </span>
              </div>
              <div className="header-right__user">
                <div className="header-right__user-img">
                  <img src={user} alt="user" width={50} height={50} />
                </div>
                <div className="header-right__user-info">
                  <div className="user-info__name">Valis Stamatis</div>
                  <div className="user-info__profession">Web Designer</div>
                </div>
              </div>
            </div>
          </div>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navigation;
