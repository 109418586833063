import "./App.css";
import Sidebar from "./components/Sidebar";
import Nav from "./components/Nav";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Overview, Users, Dumpsters, Weights } from "./pages/Overview";
import { Reports, ReportsOne, ReportsTwo, ReportsThree } from "./pages/Reports";
import Team from "./pages/Team";
import Control from "./pages/Control";

function App() {
  if (!Object.hasOwn) {
    Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
  }

  return (
    <Router>
      <div className="site-container">
        <div className="sidebar-container">
          <Sidebar />
        </div>
        <div className="content-container">
          <Nav />
          <Routes>
            <Route path="/overview" element={<Overview />} />
            <Route path="/overview/users" element={<Users />} />
            <Route path="/overview/bins" element={<Dumpsters />} />
            <Route path="/overview/weights" element={<Weights />} />
            <Route path="/reports/reports3" element={<ReportsThree />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/reports/map" element={<ReportsOne />} />
            <Route path="/reports/charts" element={<ReportsTwo />} />
            <Route path="/control" element={<Control />} />
            <Route path="/team" element={<Team />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
