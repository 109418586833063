import React, { useEffect, useState } from "react";
import axios from "axios";
import { TreeList, Scrolling, Paging, Pager, SearchPanel, Column, Sorting } from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.common.css"; // Common styles
import "devextreme/dist/css/dx.light.css"; // Theme
import API_BASE_URL from "../config";

const weightsURL = `${API_BASE_URL}/api/v1/weights`;
const usersURL = "https://jsonplaceholder.typicode.com/users";
const binsURL = `${API_BASE_URL}/api/v1/bins`;
export const Overview = () => {
  return (
    <div className="home">
      <h1>Γενικά</h1>
    </div>
  );
};
export const Users = () => {
  const [data, setData] = useState([]);
  const allowedPageSizes = [10, 20, 50];

  useEffect(() => {
    axios.get(usersURL).then((response) => {
      const jsonData = response.data;
      setData(jsonData);
      console.log("jsonData :", jsonData);
    });
  }, []);

  return (
    <div className="overview">
      <h1>Χρήστες</h1>
      {/* <div className="tbl-header">
      <table id="kadoi-table">
        <thead>
          <tr>
            <th>Client ID</th>
            <th>Bag Code</th>
            <th>Weight Info</th>
            <th>Timestamp</th>
          </tr>
        </thead>
      </table>
    </div>
    <div className="tbl-content">
      <table>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.clientID}</td>
              <td>{item.weightInfo[0].bagCode}</td>
              <td>{item.weightInfo[0].weight}</td>
              <td>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> */}

      <TreeList dataSource={data} columnAutoWidth={true} wordWrapEnabled={true} showBorders={true} keyExpr="id">
        <Scrolling mode="standard" />
        <SearchPanel visible={true} />
        <Paging enabled={true} defaultPageSize={10} />
        <Pager showPageSizeSelector={true} allowedPageSizes={allowedPageSizes} showNavigationButtons={true} />

        <Sorting mode="multiple" />

        {/* Column definitions */}
        <Column dataField="id" caption="ID" defaultSortOrder="asc" />
        <Column dataField="name" caption="Name" defaultSortOrder="asc" />
        <Column dataField="username" caption="Username" defaultSortOrder="asc" />
        <Column dataField="email" caption="Email" defaultSortOrder="asc" />
        <Column dataField="address.street" caption="Street" defaultSortOrder="asc" />
        <Column dataField="address.suite" caption="Suite" defaultSortOrder="asc" />
        <Column dataField="address.city" caption="City" defaultSortOrder="asc" />
        <Column dataField="address.zipcode" caption="Zip Code" defaultSortOrder="asc" />
        <Column dataField="address.geo.lat" caption="Latitude" defaultSortOrder="asc" />
        <Column dataField="address.geo.lng" caption="Longitude" defaultSortOrder="asc" />
        <Column dataField="phone" caption="Phone" defaultSortOrder="asc" />
        <Column dataField="website" caption="Website" defaultSortOrder="asc" />
        <Column dataField="company.name" caption="Company Name" defaultSortOrder="asc" />
        <Column dataField="company.catchPhrase" caption="Catch Phrase" defaultSortOrder="asc" />
        <Column dataField="company.bs" caption="Business BS" defaultSortOrder="asc" />
      </TreeList>
    </div>
  );
};
export const Weights = () => {
  const [data, setData] = useState([]);
  const allowedPageSizes = [10, 20, 50];

  useEffect(() => {
    axios.get(weightsURL).then((response) => {
      const jsonData = response.data;
      setData(jsonData);
      console.log("jsonData :", jsonData);
    });
  }, []);

  return (
    <div className="overview">
      <h1>Ζυγίσεις</h1>
      {/* <div className="tbl-header">
        <table id="kadoi-table">
          <thead>
            <tr>
              <th>Client ID</th>
              <th>Bag Code</th>
              <th>Weight Info</th>
              <th>Timestamp</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="tbl-content">
        <table>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.clientID}</td>
                <td>{item.weightInfo[0].bagCode}</td>
                <td>{item.weightInfo[0].weight}</td>
                <td>{item.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

      <TreeList dataSource={data} columnAutoWidth={true} wordWrapEnabled={true} showBorders={true} keyExpr="clientID">
        <Scrolling mode="standard" />
        <SearchPanel visible={true} />
        <Paging enabled={true} defaultPageSize={10} />
        <Pager showPageSizeSelector={true} allowedPageSizes={allowedPageSizes} showNavigationButtons={true} />

        <Sorting mode="multiple" />

        <Column dataField="clientID" caption="Όνομα Κάδου" defaultSortOrder="asc" />
        <Column dataField="timestamp" caption="Ημερομηνία Ζύγισης" dataType="date" defaultSortOrder="asc" />
        <Column dataField="weightInfo[0].bagCode" caption="Κωδικός QR" defaultSortOrder="asc" />
        <Column
          dataField="weightInfo[0].weight"
          caption="Βάρος (γραμμάρια)"
          dataType="number" // Assuming weight is a number
          defaultSortOrder="asc"
        />
      </TreeList>
    </div>
  );
};
export const Dumpsters = () => {
  const [data, setData] = useState([]);
  const allowedPageSizes = [10, 20, 50];

  useEffect(() => {
    axios.get(binsURL).then((response) => {
      const jsonData = response.data;
      setData(jsonData);
      console.log("jsonData :", jsonData);
    });
  }, []);

  return (
    <div className="overview">
      <h1>Κάδοι</h1>
      {/* <div className="tbl-header">
        <table id="kadoi-table">
          <thead>
            <tr>
              <th>Client ID</th>
              <th>Bag Code</th>
              <th>Weight Info</th>
              <th>Timestamp</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="tbl-content">
        <table>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.clientID}</td>
                <td>{item.weightInfo[0].bagCode}</td>
                <td>{item.weightInfo[0].weight}</td>
                <td>{item.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

      <TreeList dataSource={data} columnAutoWidth={true} wordWrapEnabled={true} showBorders={true} keyExpr="_id">
        <Scrolling mode="standard" />
        <SearchPanel visible={true} />
        <Paging enabled={true} defaultPageSize={10} />
        <Pager showPageSizeSelector={true} allowedPageSizes={allowedPageSizes} showNavigationButtons={true} />

        <Sorting mode="multiple" />

        {/* Column definitions */}
        <Column dataField="_id" caption="Όνομα Κάδου" defaultSortOrder="asc" />
        <Column dataField="timestamp" caption="Τελευταία Ενημέρωση" dataType="date" defaultSortOrder="asc" />
        <Column dataField="locationName" caption="Περιοχή" defaultSortOrder="asc" />
        <Column dataField="battery" caption="Ποσοστό Μπαταρία (%)" dataType="number" defaultSortOrder="asc" />
        <Column
          dataField="fill"
          caption="Ποσοστό Πληρώτητας (%)"
          dataType="number" // Assuming weight is a number
          defaultSortOrder="asc"
        />
      </TreeList>
    </div>
  );
};

export default Overview;
